<template>
  <div
    id="landing-container" 
    class="flex flex-col items-center mt-2 justify-center"
  >
    <div
      id="apps-container"
      data-testid="apps-container"
      class="flex flex-row flex-wrap overflow-y-scroll gap-8 justify-evenly mt-8 z-10"
      :class="maxWidth"
    >
      <div
        v-for="item in workspaceApps"
        :key="item.name"
      >
        <app-logo :app-data="item" />
      </div>
    </div>
  </div>
  <sima-footer />
</template>

<script setup lang="ts">
import { AppLogo, SimaFooter } from '@/components/index.ts'
import { simaDesktopApps } from '@/mocks/index'
import type { WorkspaceApp } from '@/types.ts'
import { useAppStore, usePersistentStore } from '@/store'
import { computed } from 'vue'

const store = useAppStore()
const showSimaDesktop: boolean  = usePersistentStore().getBooleanParamByKey('showsimadesktop')
const workspaceApps: WorkspaceApp[] = showSimaDesktop && import.meta.env.DEV
  ? [...simaDesktopApps, ...store.apps] 
  : store.apps

const maxWidth = computed(()=>{
  let styleString = ''
  workspaceApps.length === 2 || workspaceApps.length === 4 ?
    styleString = 'max-w-sm'
    : workspaceApps.length >= 3 && workspaceApps.length <= 6 ?
      styleString = 'max-w-xl'
      : styleString = 'max-w-3xl'
  return styleString
})
</script>

<style scoped>
*{
  -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
}
#landing-container {
  min-height: 60vh;
}
  #apps-container div:nth-child(1) {animation: fadeIn .6s ease-in both 0s; }
  #apps-container div:nth-child(2) {animation: fadeIn .6s ease-in both .15s; }
  #apps-container div:nth-child(3) {animation: fadeIn .6s ease-in both .30s; }
  #apps-container div:nth-child(4) { animation: fadeIn .6s ease-in both .45s; }
  #apps-container div:nth-child(5) {animation: fadeIn .6s ease-in both 1s; }
  #apps-container div:nth-child(6) { animation: fadeIn .6s ease-in both 1.15s; }
  #apps-container div:nth-child(7) {animation: fadeIn .6s ease-in both 1.3s; }
  #apps-container div:nth-child(8) { animation: fadeIn .6s ease-in both 1.45s; }
  @keyframes fadeIn {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
</style>