import { createApp } from 'vue'
import { createPinia } from 'pinia'
import './assets/tailwind.css'
import './assets/fonts/fonts.css'
import App from './App.vue'
import { getRouter } from './router'
import { requiredUrls } from './store/app/types'
import { configureSimaSuiteComponents } from 'sima-suite-components-vue3'
import { loadConfiguration } from './configuration'
import { provideAuthentication } from 'sima-suite-authentication'
import { configureTranslations } from 'sima-suite-translator-vue3'
import { checkThatUserIsLoggedIn } from './router/routerAuthentication'
import { useAppStore, usePersistentStore } from './store'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import translations from './translations'
import VueGtag from 'vue-gtag'
import VueHotjar from 'vue-hotjar-next'
import PackageJson from '../package.json'
import { Release, initMonitoring } from './plugins/monitoring'

configureTranslations(translations)
const app = createApp(App)
app.use(createPinia().use(piniaPluginPersistedstate))

const persistentStore = usePersistentStore()
persistentStore.init()

const {
  authentication,
  backendUrl,
  googleAnalyticsKey,
  sentryKey,
  hotjarKey,
  environment,
  workspaceApps,
  poweredByUrl,
  helpUrl,
  newsletterUrl,
  changePasswordUrl,
  manageUserDataUrl,
  productFruitKey
} = await loadConfiguration()
authentication.silentRenew = ''
provideAuthentication(authentication)

const store = useAppStore()
store.init(workspaceApps, {
  poweredByUrl: poweredByUrl,
  helpUrl: helpUrl,
  newsletterUrl: newsletterUrl,
  manageUserDataUrl: manageUserDataUrl,
  changePasswordUrl: changePasswordUrl,
  productFruitKey
} as requiredUrls)

const router = getRouter(checkThatUserIsLoggedIn)
app.use(router)
configureSimaSuiteComponents(app)

/* Replace with you GTag id */
app.use(VueGtag, {
  config: {
    id: googleAnalyticsKey || ''
  }
})

/* Initialize hotjar */
if (hotjarKey) {
  app.use(VueHotjar, {
    id: +hotjarKey,
    isProduction: import.meta.env.PROD,
    snippetVersion: 6
  })
}

/**
 * Sentry monitoring configuration.
 * Change sentryKey and set devMode to false.
 **/
initMonitoring({
  app,
  release: new Release(PackageJson.name, PackageJson.version),
  key: sentryKey ?? '',
  router: router,
  url: backendUrl,
  environment: environment ?? '',
  productionEnvironments: ['Prod', 'QA'],
  devMode: false // Allow to register in sentry in dev mode
})

app.mount('#app')
