import { defineStore } from 'pinia'
import { AppState, Param } from './types'
import { z } from 'zod'
import { LocationQuery } from 'vue-router'

const validateBoolean = z
  .string()
  .toLowerCase()
  .nullable()
  .transform((x) => x === 'true')
  .pipe(z.boolean())

const paramsToSave = ['showsimadesktop', 'showuserdatamanage']

export const usePersistentStore = defineStore({
  id: 'persistentStore',
  state: (): AppState => ({
    queryParams: []
  }),
  actions: {
    init(): void {
      const params = new URLSearchParams(window.location.href.split('?')[1] ?? '')
      params.forEach((value, key) => this.addParam(key, value))
    },
    addParam(key: string, value: string): void {
      //As a persist store if the param existed before in the session it has to be updated
      const elementIndex = this.queryParams.findIndex((o) => o.name === key.toLowerCase())
      if (elementIndex > -1) this.queryParams.splice(elementIndex, 1)
      if (paramsToSave.some((p) => p === key.toLowerCase())) {
        this.queryParams.push({ name: key.toLowerCase(), value: value.toLowerCase() })
      }
    },
    getBooleanParamByKey(key?: string): boolean {
      const param: Param | undefined = this.queryParams.find((o) => o.name === key)
      return param ? validateBoolean.parse(param.value) : false
    }
  },
  getters: {
    getQueryFromAllParams(): LocationQuery {
      const query: LocationQuery = {}
      this.queryParams.forEach((param) => {
        query[param.name] = param.value
      })
      return query
    }
  },
  persist: {
    storage: sessionStorage
  }
})
export type usePersistentStore = ReturnType<typeof usePersistentStore>
