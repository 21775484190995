<template>
  <div
    id="footer-container"
    class="w-full absolute bottom-0 left-0"
  >
    <div 
      class="absolute left-0 w-full h-full bg-cover bg-top" 
      :style="{ backgroundImage: `url(${path}/background_wave.svg)`}"
    />
    <div
      class="absolute left-0 w-full h-full xl:bg-contain bg-top" 
      :style="{ backgroundImage: `url(${path}/AIDA_corporative_wave.svg)`}"
    />
    <a
      class="developed_link flex items-center w-full h-1/5 justify-center -left-0 absolute -bottom-0 b2-typography"
      :href="poweredByUrl"
      target="_blank"
      rel="noopener noreferrer"
      :title="descriptionText"
    >
      <span class="pr-1">{{ translate("Home.DevelopedBy") }}</span>
      <img
        class="developed-default"
        :src="`${path}/logo_AIDA.svg`"
        :alt="descriptionText"
      >
      <img
        class="developed-hover"
        :src="`${path}/logo_AIDA_hover.svg`"
        :alt="descriptionText"
      >
    </a>
  </div>
</template>
  
<script setup lang="ts">
import { useTranslator } from 'sima-suite-translator-vue3'
import { useAppStore } from '@/store/app'
import { computed } from 'vue'

const { translate, language } = useTranslator()
const store = useAppStore()
const path = new URL('/footerIcons', import.meta.url)

const poweredByUrl = computed(()=> `${store.urls.poweredByUrl}${language.value !== 'es'? 'en/home' : ''}`)
const descriptionText = computed(()=>`${translate('Home.DevelopedBy')} AIDA`)

</script>
  
<style scoped>
#footer-container {
  height: 30%;
}
.developed_link {
  text-decoration: none;
  color: #0B0B0C;
  opacity: .57;
}
.developed_link:hover {
  opacity: 1;
}
.developed_link:hover .developed-default {
  display: none;
}
 .developed_link:hover .developed-hover {
  display: block;
}
.developed-default {
  display: block;
}
.developed-hover {
  display: none;
}
</style>