<template>
  <s-app-layout
    v-if="isLogged"
    :title="translate('App.Title')"
    :src-image="appLogo"
    :user-profile="userData"
    :show-notifications="false"
    :toolbar-title="toolbarTitle"
    :language="appLanguages"
    @logout-button-click="userStore.logout"
    @language-selected="onLanguageSelected"
  >
    <router-view />
    <template
      v-if="showUserDataManage"
      #profile-content >
      <profile-options />
    </template>
    <template #content-head-right>
      <s-icon-button
        role="button"
        :icon="'mdi-help'"
        aria-label="Help"
        title="Help"
        data-testid="help"
        @icon-button-click="onClickHelp"
      />
    </template>  
    <template #toolbar-right-content>
      <s-button 
        class="normal-case"
        color="#FF2957"
        text="Newsletter"
        @button-click="onClickNewsletter"
      />
    </template> 
  </s-app-layout>
</template>
<script setup lang="ts">
import { useUserStore, useAppStore, usePersistentStore } from '@/store'
import { onBeforeMount, ref, computed } from 'vue'
import { SAppLayout, SIconButton, setSimaSuiteComponentsLanguage, SButton } from 'sima-suite-components-vue3'
import { useTranslator } from 'sima-suite-translator-vue3'
import { Language, SUserProfile } from './type'
import appLogo from '@/assets/applogo.svg'
import { ProfileOptions } from '@/components/index.ts'
import { productFruits } from 'product-fruits'

const userStore =  useUserStore()
const appStore = useAppStore()
const { translate, language, setLanguage } = useTranslator()
const showUserDataManage: boolean = usePersistentStore().getBooleanParamByKey('showuserdatamanage')

const isLogged = ref(false)

const onClickHelp = (()=>{
  window.open(appStore.urls.helpUrl, '_blank')?.focus()
})

const onClickNewsletter = (()=>{
  window.open(appStore.urls.newsletterUrl, '_blank')?.focus()
})

const languages = [{ key: 'es' }, { key: 'en' }]

const userData = computed(()=>{
  return userStore.isLogged ? userStore.user as SUserProfile : undefined
})

const toolbarTitle = computed(()=>{
  return userStore.isLogged ? `${translate('App.Greeting')}, ${userData.value?.name}` : ''
})

const appLanguages = computed(() => {
  const langs: { key: string; value: any }[] = []
  const currentLanguage = languages.find((lang: Language) => lang.key === language.value)
  
  langs.push(createAppLanguage(currentLanguage!.key))
  languages.forEach((lang: Language) => {
    if (lang.key !== language.value) {
      langs.push(createAppLanguage(lang.key))
    }
  })

  return {
    options: langs,
    visibility: true
  }
})

const createAppLanguage = (lang: string) => {
  return {
    key: lang,
    value: translate(`App.Language.${lang}`)
  }
}

const onLanguageSelected = (selectedLanguage: Language) => {
  setLanguage(selectedLanguage.key)
  setSimaSuiteComponentsLanguage(selectedLanguage.key)
}

onBeforeMount(async()=> {
  await userStore.getUserProfile()
  if(userStore.isLogged) isLogged.value = true

  if (appStore.urls.productFruitKey && userStore.user?.email) {
    productFruits.init(
      appStore.urls.productFruitKey,
      window.navigator.language.split('-')[0],
      { 
        username: userStore.user?.email,
        email: userStore.user?.email,
        firstname: userStore.user?.name
      }
    )
  }
})

</script>

<style scoped>
:deep(.ssc-flex) {
  align-items: center;
}
</style>