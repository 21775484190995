<template>
  <div />
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useAuthentication } from 'sima-suite-authentication'
import { useRouter } from 'vue-router'
import { usePersistentStore } from '@/store'

const router = useRouter()
onMounted(async () => {
  const { redirect, logout } = useAuthentication()
  const usr: any = await redirect()
  const isEmptyUser = JSON.stringify(usr) === JSON.stringify({})
  if (isEmptyUser) {
    return logout()
  }

  const params = usePersistentStore().getQueryFromAllParams
  router.push({ path: '/', query: params })
})
</script>


